import React, { Component } from 'react';
import Chart from "chart.js";
import classes from "./Graph.module.css";

let myLineChart;

export default class Graph extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.buildChart();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        const { datasets } = this.props;

        if (typeof myLineChart !== "undefined") myLineChart.destroy();

        var labels = [...Array(32).keys()];

        myLineChart = new Chart(myChartRef, {
            type: "line",
            data: {
                //Bring in data
                labels: labels,
                datasets: datasets
            },
            options: {
                //Customize chart options
                title: {
                    display: true,
                    text: 'Income Monitor'
                },
                tooltips: {
                    mode: 'x'
                },
                responsive: true,
                maintainAspectRatio: false
            }
        });

    }

    render() {
        return (
            <div className={classes.graphContainer}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}