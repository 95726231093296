import React from 'react';
import classes from './Select.module.css';

const select = (props) => {
    let options = [];
    let value = "";
	if (props.options.length) {
		options = props.options.map((option, i) => (
			<option value={option.value} key={option.value + i}>
				{option.label}
			</option>
		));
		value = props.value.value;
	}
	return (
		<div className={classes.Container}>
			<div className={classes.LabelDiv}>
				<label>{props.label}</label>
			</div>
			<div className={classes.SelectDiv}>
				<select
					disabled={props.disabled}
					value={value ? value : ''}
					onChange={(e) => {
						let newValue = null;
						for (const option of props.options) {
							if (option.value === e.target.value) {
								newValue = option;
								break;
							}
						}
						props.onChange(newValue ? newValue : '');
					}}
					className={classes.Select}>
					{options}
				</select>
			</div>
		</div>
	);
};

export default select;
