import React, { Component } from 'react';
import axios from 'axios';
import classes from "./IncomeMonitor.module.css";
import Graph from "../../Components/Graph/Graph";
import Select from "../../Components/Select/Select";

const API_PATH = '/wp-admin/admin-ajax.php';

export default class IncomeMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            datasets: [], 
            type: {value: 'combined', label: 'Income minus refunds'},
            types: [{value: 'combined', label: 'Income Minus Refunds'}, {value: 'income', label: 'Income Without Refunds'}, {value: 'refunds', label: 'Refunds'}],
            loading: true
        };
    }

    loadDatasets = (type) => {
        var today = new Date();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();
        var params = new URLSearchParams();
        params.append('action', 'getIncomeData');
        params.append('month', month);
        params.append('year', year);
        params.append('type', type);
        var currentComponent = this;
		axios.post(API_PATH, params)
            .then( function (response) {
                currentComponent.setState({ datasets: response.data.datasets, loading: false });
            })
            .catch( function (error) {
                console.log(error);
            });
    }

    componentWillMount () {
        this.loadDatasets('combined');
	}

    onChange = (value) => {
        this.setState({ type: value, loading: true, datasets: [] });
        this.loadDatasets(value.value);
    }

    render() {
        const { datasets, types, type, loading } = this.state;
        return (
            <div className={classes.container}>
                <Select 
                    label="Graph Type: "
                    options={types}
                    value={type}
                    disabled={loading}
                    onChange={(value) => this.onChange(value)}
                />
                <Graph
                    datasets={datasets} />
            </div>
        )
    }
}
